export enum PrimeIcon {
  ARROW_LEFT = 'pi pi-arrow-left',
  ARROW_RIGHT = 'pi pi-arrow-right',
  BAN = 'pi pi-ban',
  BARS = 'pi pi-bars',
  BOOK = 'pi pi-book',
  BOLT = 'pi pi-bolt',
  BOX = 'pi pi-box',
  BRIEFCASE = 'pi pi-briefcase',
  BUILDING = 'pi pi-building',
  CALENDAR = 'pi pi-calendar',
  CREDIT_CARD = 'pi pi-credit-card',
  CHART_BAR = 'pi pi-chart-bar',
  CHART_LINE = 'pi pi-chart-line',
  CHART_PIE = 'pi pi-chart-pie',
  CHECK = 'pi pi-check', 
  CHECK_CIRCLE = 'pi pi-check-circle',
  CHECK_SQUARE = 'pi pi-check-square',
  CHEVRON_DOWN = 'pi pi-chevron-down',
  CHEVRON_RIGHT = 'pi pi-chevron-right',
  CHEVRON_UP = 'pi pi-chevron-up',
  CLOCK = 'pi pi-clock',
  CLOUD_UPLOAD = 'pi pi-cloud-upload',
  CODE = 'pi pi-code',
  COG = 'pi pi-cog',
  COMMENT = 'pi pi-comment',
  COPY = 'pi pi-copy',
  DEFAULT = 'pi pi-circle',
  DOLLAR = 'pi pi-dollar',
  DOWNLOAD = 'pi pi-download',
  ELLIPSIS_V = 'pi pi-ellipsis-v',
  ENVELOPE = 'pi pi-envelope',
  EXCLAMATION_CIRCLE = 'pi pi-exclamation-circle',
  EXCLAMATION_TRIANGLE = 'pi pi-exclamation-triangle',
  EXTERNAL_LINK = 'pi pi-external-link',
  EYE = 'pi pi-eye',
  EYE_SLASH = 'pi pi-eye-slash',
  FILE_ANY = 'pi pi-file',
  FILE_EDIT = 'pi pi-file-edit',
  FILE_EXCEL = 'pi pi-file-excel',
  FILE_IMAGE = 'pi pi-image',
  FILE_PDF = 'pi pi-file-pdf',
  FILTER = 'pi pi-filter',
  FLAG = 'pi pi-flag',
  HISTORY = 'pi pi-history',
  INFO_CIRCLE = 'pi pi-info-circle',
  LINK = 'pi pi-link',
  LIST = 'pi pi-list',
  LOADING = 'pi pi-spinner pi-spin',
  LOCK = 'pi pi-lock',
  LOCK_OPEN = 'pi pi-lock-open',
  LOGOUT = 'pi pi-fw pi-sign-out',
  MINUS = 'pi pi-minus',
  MINUS_CIRCLE = 'pi pi-minus-circle',
  MONEY_BILL = 'pi pi-money-bill',
  PAPERCLIP = 'pi pi-paperclip',
  PENCIL = 'pi pi-pencil',
  PLUS = 'pi pi-plus',
  PLUS_CIRCLE = 'pi pi-plus-circle',
  PRINT = 'pi pi-print',
  QRCODE = 'pi pi-qrcode',
  QUESTION_CIRCLE = 'pi pi-question-circle',
  REFRESH = 'pi pi-refresh',
  SAVE = 'pi pi-save',
  SEARCH = 'pi pi-search',
  SEND = 'pi pi-send',
  SHARE = 'pi pi-share-alt',
  SIDEBAR = 'pi pi-th-large',
  SITEMAP = 'pi pi-sitemap',
  STAR = 'pi pi-star',
  SUN = 'pi pi-fw pi-sun',
  TABLE = 'pi pi-table',
  TAG = 'pi pi-tag',
  THUMBS_DOWN = 'pi pi-thumbs-down',
  THUMBS_UP = 'pi pi-thumbs-up',
  TICKET = 'pi pi-ticket',
  TIMES = 'pi pi-times',
  TIMES_CIRCLE = 'pi pi-times-circle',
  TRASH = 'pi pi-trash',
  UNDO = 'pi pi-undo',
  UNLOCK = 'pi pi-unlock',
  UPLOAD = 'pi pi-upload',
  USER = 'pi pi-user',
  WHATSAPP = 'pi pi-whatsapp'
}